import produce from 'immer';
import { Action } from 'redux-actions';

import { ActionType } from './actions';
import { Entities } from './types';

export interface State {
  entities: Entities;
}

const EMPTY_STATE: State = {
  entities: {},
};

export default produce((draft: State, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionType.FETCH_SUCCESS: {
      const { coinId, analysis } = payload;

      draft.entities[coinId] = analysis;
      return;
    }
  }
}, EMPTY_STATE);
