import { createAction } from 'redux-actions';

import { Currency } from './types';

export enum ActionType {
  SET_ACTIVE = 'currency.set.active',
  FETCH_ALL = 'currency.fetch.all',
  FETCH_ALL_SUCCESS = 'currency.fetch.all.success',
}

export const setActive = createAction(
  ActionType.SET_ACTIVE,
  (item: Currency) => ({ item }),
);
export const fetchAll = createAction(ActionType.FETCH_ALL);
export const fetchAllSuccess = createAction(
  ActionType.FETCH_ALL_SUCCESS,
  (items: Currency[]) => ({ items }),
);
