import * as Sentry from '@sentry/nextjs';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';

import { ActionType, FetchAction, fetchSuccess } from './actions';

function* fetchFlow({ payload }: FetchAction) {
  const { coinId } = payload;

  try {
    const { data: response, status } = yield call(
      api.get,
      `/coins/${coinId}/info`,
    );

    if (status !== 200) {
      return;
    }

    if (response.meta.success === false) {
      return;
    }

    yield put(fetchSuccess(coinId, response.data));
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* fetchFlowWatcher() {
  yield takeEvery(ActionType.FETCH, fetchFlow);
}

export default [fork(fetchFlowWatcher)];
