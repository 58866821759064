import { addYears } from 'date-fns';
import { fork, takeEvery } from 'redux-saga/effects';
import CookieService, { Cookies } from 'services/cookie';

import { ActionType, SetCookiesAllowedAction } from './actions';

function setCookiesAllowedFlow({ payload }: SetCookiesAllowedAction) {
  const { value } = payload;

  CookieService.set(Cookies.COOKIE_CONSENT_GIVEN, value, {
    path: '/',
    expires: addYears(new Date(), 2),
  });
}

function* setDataFlowWatcher() {
  yield takeEvery(ActionType.SET_COOKIES_ALLOWED, setCookiesAllowedFlow);
}

export default [fork(setDataFlowWatcher)];
