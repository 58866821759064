import { combineReducers } from 'redux';

import coinAnalysis from './Coin/Analysis/reducer';
import coinDirect from './Coin/Direct/reducer';
import coinInfo from './Coin/Info/reducer';
import currency from './Currency/reducer';
import marketChart from './Market/Chart/reducer';
import market from './Market/reducer';
import marketsSummary from './Market/Summary/reducer';
import misc from './Misc/reducer';

export default combineReducers({
  coinInfo,
  coinDirect,
  coinAnalysis,
  currency,
  marketsSummary,
  market,
  marketChart,
  misc,
});
