import { Action, createAction } from 'redux-actions';

import { CoinAnalysis } from './types';

export enum ActionType {
  FETCH = 'coin.analysis.fetch',
  FETCH_SUCCESS = 'coin.analysis.fetch.success',
}

export type FetchAction = Action<{ coinId: string }>;

export const fetch: (coinId: string) => FetchAction = createAction(
  ActionType.FETCH,
  (coinId: string) => ({
    coinId,
  }),
);

export const fetchSuccess = createAction(
  ActionType.FETCH_SUCCESS,
  (coinId: string, analysis: CoinAnalysis) => ({ coinId, analysis }),
);
