import { createAction } from 'redux-actions';

import { MarketsSummary } from './types';

export const FETCH = 'market.summary.fetch';
export const FETCH_SUCCESS = 'market.summary.fetch.success';

export const fetch = createAction(FETCH);
export const fetchSuccess = createAction(
  FETCH_SUCCESS,
  (marketsSummary: MarketsSummary) => ({
    marketsSummary,
  }),
);
