export default {
  blackLight: '#1a1a1a',
  dirtyWhite: 'rgba(255,255,255,0.5)',
  white: '#fff',
  black: '#000',
  darkGrey: '#333333',
  lightGrey: '#bbbbbb',
  dividerColor: 'rgba(255,255,255,0.15)',
  red: '#f0162f',
  green: '#00b061',
  blue: '#1cafff',
  purple: '#6236ff',
  orange: '#ff9f00',
  grays: {
    200: '#ededed',
    800: '#242424',
  },
};
