import React from 'react';

import { Item, List } from './styles';

interface Props {
  periods: string[];
  selectedPeriod: string;
  onChange: (period: string) => void;
}

export enum TimePeriods {
  Week = '1W',
  Day = '1D',
  Hour = '1H',
}

const TimePeriodPicker = (props: Props) => {
  const { periods, selectedPeriod, onChange } = props;

  return (
    <List>
      {periods.map((period, index) => {
        const onItemClick = () => {
          if (period === selectedPeriod) {
            return;
          }

          onChange(period);
        };

        return (
          <Item
            key={`key_${index}_${period}`}
            isSelected={period === selectedPeriod}
            onClick={onItemClick}
          >
            {period}
          </Item>
        );
      })}
    </List>
  );
};

TimePeriodPicker.defaultProps = {
  periods: Object.values(TimePeriods),
};

export default TimePeriodPicker;
