import accounting from 'accounting';

import { Currency as CurrencyType, USD } from '../../store/Currency/types';

export const getPrecisionForNumber = (value: number): number => {
  if (value > -0.00000000999 && value < 0.00000000999) {
    return 9;
  }

  if (value > -1 && value < 1) {
    return 8;
  }

  if (value > 10000) {
    return 0;
  }

  return 2;
};

export const formatNumber = (value: number): string => {
  const precision = getPrecisionForNumber(value);

  return accounting.formatNumber(value, { precision });
};

export const formatMoney = (
  rawPrice: number,
  currency: CurrencyType = USD,
): string => {
  const price = rawPrice * currency.rate;
  const precision = getPrecisionForNumber(price);

  return `${price < 0 ? '-' : ''}${accounting.formatMoney(Math.abs(price), {
    symbol: currency.symbol,
    precision,
  })}`;
};

export const format = (
  value: number,
  currency: CurrencyType | null = null,
): string => {
  if (currency) {
    return formatMoney(value, currency);
  }

  return formatNumber(value);
};

export const formatPercentage = (value: number): string =>
  `${accounting.toFixed(value, 2)}%`;

interface HumanReadableNumberOptions {
  currency?: CurrencyType | null;
  formatThousands?: boolean;
}

export const formatHumanReadableNumber = (
  value: number,
  currencyOrOptions?: CurrencyType | HumanReadableNumberOptions,
): string => {
  let currency: CurrencyType | null = null;
  let formatThousands: boolean = false;

  if (
    currencyOrOptions &&
    currencyOrOptions.hasOwnProperty('currency') &&
    currencyOrOptions.hasOwnProperty('symbol') &&
    currencyOrOptions.hasOwnProperty('rate')
  ) {
    currency = currencyOrOptions as CurrencyType;
  } else if (currencyOrOptions) {
    if (currencyOrOptions.hasOwnProperty('currency')) {
      currency = currencyOrOptions.currency as CurrencyType;
    }
    if (currencyOrOptions.hasOwnProperty('formatThousands')) {
      formatThousands =
        (currencyOrOptions as HumanReadableNumberOptions).formatThousands ||
        false;
    }
  }

  const realValue = value * (currency ? currency.rate : 1);

  // Trillions?
  if (realValue >= Math.pow(10, 12)) {
    return `${format(value / Math.pow(10, 12), currency)}T`;
  }

  // Billions?
  if (realValue >= Math.pow(10, 9)) {
    return `${format(value / Math.pow(10, 9), currency)}B`;
  }

  // Millions?
  if (realValue >= Math.pow(10, 6)) {
    return `${format(value / Math.pow(10, 6), currency)}M`;
  }

  // Thousands
  if (formatThousands && realValue >= Math.pow(10, 3)) {
    return `${format(value / Math.pow(10, 3), currency)}K`;
  }

  return format(value, currency);
};
