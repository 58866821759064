import axios from 'axios';
import axiosRetry from 'axios-retry';
import { stringify } from 'qs';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  paramsSerializer: (params: any) => stringify(params),
  validateStatus: (status) => {
    if (status === 404) {
      return true;
    }

    return status >= 200 && status < 300;
  },
});
axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
});

export default client;
