import { createAction } from 'redux-actions';

import { ChartDataPoint } from './types';

export const FETCH = 'market.charts.fetch';
export const FETCH_SUCCESS = 'market.charts.fetchSuccess';

export const fetch = createAction(FETCH, (coinId: string, period: string) => ({
  coinId,
  period,
}));

export const fetchSuccess = createAction(
  FETCH_SUCCESS,
  (coinId: string, period: string, data: ChartDataPoint[]) => ({
    coinId,
    period,
    data,
  }),
);
