import produce from 'immer';
import { Action } from 'redux-actions';

import { FETCH_SUCCESS } from './actions';
import { ChartDataPoint } from './types';

export interface State {
  data: {
    [coinId: string]: ChartDataPoint[];
  };
  period: string | null;
}

const EMPTY_STATE: State = {
  data: {},
  period: null,
};

export default produce((draft: State, { type, payload }: Action<any>) => {
  switch (type) {
    case FETCH_SUCCESS: {
      const {
        coinId,
        period,
        data,
      }: { coinId: string; period: string; data: ChartDataPoint[] } = payload;

      draft.data[coinId] = data;
      draft.period = period;
      return;
    }
  }
}, EMPTY_STATE);
