import * as Sentry from '@sentry/nextjs';
import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

const isDev = !['production', 'staging'].includes(
  process.env.NEXT_PUBLIC_MODE!,
);

export const cache = {
  retrieve: () => {
    if (typeof window === 'undefined') {
      return {};
    }

    if (!window.localStorage) {
      return {};
    }

    try {
      const serializedState = localStorage.getItem('store.state');

      if (!serializedState) {
        return {};
      }

      return JSON.parse(serializedState);
    } catch (e) {
      Sentry.captureException(e);
    }
  },
  store: (state: any) => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!window.localStorage) {
      return;
    }

    if (!state) {
      return;
    }

    try {
      const serializedState = JSON.stringify(state);

      if (!serializedState) {
        return;
      }

      localStorage.setItem('store.state', serializedState);
    } catch (e) {
      Sentry.captureException(e);
    }
  },
};

export default (initialState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = isDev
    ? // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('redux-devtools-extension').composeWithDevTools
    : compose;
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
  const store: any = createStore(
    reducers,
    merge(initialState, cache.retrieve()),
    enhancer,
  );
  store.sagaTask = sagaMiddleware.run(sagas);

  store.subscribe(
    throttle(() => {
      const state = { ...store.getState() };

      // Don't cache charts
      if (state.marketChart) {
        delete state.marketChart;
      }

      // Don't cache coins
      if (state.coin) {
        delete state.coin;
      }

      // Don't cache coin directs
      if (state.coinDirect) {
        delete state.coinDirect;
      }

      // Don't cache misc
      if (state.misc) {
        delete state.misc;
      }

      cache.store(state);
    }, 100),
  );

  return store;
};
