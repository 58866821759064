import { createAction } from 'redux-actions';

import { Market } from './types';

export const FETCH = 'market.fetch';
export const FETCH_SUCCESS = 'market.fetch.success';

export const fetch = createAction(FETCH, (coinId: string) => ({ coinId }));
export const fetchSuccess = createAction(
  FETCH_SUCCESS,
  (coinId: string, market: Market) => ({
    coinId,
    market,
  }),
);
