import * as Sentry from '@sentry/nextjs';
import { Action } from 'redux-actions';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';

import { FETCH, fetchSuccess } from './actions';

function* fetchMarketFlow({ payload }: Action<{ coinId: string }>) {
  try {
    if (!payload) {
      return;
    }

    const { coinId } = payload;

    const { data: response, status } = yield call(
      api.get,
      `/markets/${coinId}`,
    );

    if (status !== 200) {
      return;
    }

    if (response.meta.success === false) {
      return;
    }

    yield put(fetchSuccess(coinId, response.data));
  } catch (e) {
    Sentry.captureException(e);
  }
}
function* fetchMarketFlowWatcher() {
  yield takeEvery(FETCH, fetchMarketFlow);
}

export default [fork(fetchMarketFlowWatcher)];
