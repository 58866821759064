import produce from 'immer';
import { Action } from 'redux-actions';

import { FETCH_SUCCESS } from './actions';
import { Market } from './types';

export interface State {
  data: { [key: string]: Market };
}

const EMPTY_STATE: State = {
  data: {},
};

export default produce((draft: State, { type, payload }: Action<any>) => {
  switch (type) {
    case FETCH_SUCCESS: {
      const { coinId, market }: { coinId: string; market: Market } = payload;

      draft.data[coinId] = market;
      return;
    }
  }
}, EMPTY_STATE);
