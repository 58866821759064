export default {
  colors: {
    volumeBar: 'rgba(255,255,255,0.15)',
    priceLine: '#2EBAF8',
    priceDot: 'rgba(46, 186, 248, 0.35)',
    xAxisDotGlow: 'rgba(255,255,255,0.15)',
    xAxisDot: '#334153',
    grid: 'rgba(255,255,255,0.12)',
    cursor: '#fff',
  },
};
