import * as Sentry from '@sentry/nextjs';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';

import { ActionType, FetchAllAction, fetchAllDirectsSuccess } from './actions';

function* fetchAllFlow({ payload }: FetchAllAction) {
  const { coinId } = payload;

  try {
    const { data: response, status } = yield call(
      api.get,
      `/coins/${coinId}/directs`,
    );

    if (status !== 200) {
      return;
    }

    if (response.meta.success === false) {
      return;
    }

    // Filter out directs without a link
    const directs = response.data.filter((item: any) => !!item.link);

    yield put(fetchAllDirectsSuccess(coinId, directs));
  } catch (e) {
    Sentry.captureException(e);
  }
}
function* fetchAllFlowWatcher() {
  yield takeEvery(ActionType.FETCH_ALL, fetchAllFlow);
}

export default [fork(fetchAllFlowWatcher)];
