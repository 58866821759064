import { css, styled } from 'styles';

export const List = styled.ul`
  display: flex;
  flex-direction: row;
`;

interface ItemProps {
  isSelected: boolean;
}

export const Item = styled.li<ItemProps>`
  font-size: ${({ theme }) => theme.fonts.getSizeInRem(11)};
  padding: 1px 8px 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dirtyWhite};
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background: ${props.theme.colors.white};
      color: ${props.theme.colors.black};
      border-radius: 12px;
    `}
`;
