import translations, { SupportedLanguage } from '@getdelta/translations/web';
import usePrevious from '@rooks/use-previous';
import {
  getPopulatedDynamicURL,
  getTranslatedURLBasePath,
} from 'helpers/translation';
import { useCookie } from 'hooks/use-cookie';
import { useBrowserLanguage } from 'hooks/useBrowserLanguage';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { Cookies } from 'services/cookie';

interface ContextType {
  language: SupportedLanguage;
  setLanguageAndCookie: (languageToSet: SupportedLanguage) => void;
}

const LanguageContext = React.createContext<ContextType | null>(null);

interface Props {
  languageHeaders: string;
  children: React.ReactNode;
}

export const LanguageContextProvider = (props: Props) => {
  const { languageHeaders, children } = props;

  const router = useRouter();
  const { browserLanguage, setBrowserLanguage } = useBrowserLanguage();
  const [cookieLanguage, setCookieLanguage] = useCookie(
    Cookies.LANGUAGE,
    SupportedLanguage.English,
  );

  useEffect(() => {
    if (!languageHeaders) {
      return;
    }

    setBrowserLanguage(languageHeaders);
  }, [languageHeaders, setBrowserLanguage]);

  const [language, setLanguage] = useState(
    (router.locale ??
      cookieLanguage ??
      browserLanguage ??
      SupportedLanguage.English) as SupportedLanguage,
  );
  const previousLanguage = usePrevious(language);

  translations.init({
    initReactI18next: initReactI18next as any,
    lang: language,
  });

  const setLanguageAndCookie = useCallback(
    (languageToSet: SupportedLanguage) => {
      if (!Object.values(SupportedLanguage).includes(languageToSet)) {
        return;
      }

      setLanguage(languageToSet);
      setCookieLanguage(languageToSet, {
        path: '/',
        expires: new Date(2147483647000),
      });
      translations.changeLanguage(languageToSet);

      const pathname = getPopulatedDynamicURL(router.pathname, router.query);
      const path = getTranslatedURLBasePath(pathname);

      router.push({ pathname: path, query: router.query }, path, {
        locale: languageToSet,
      });
    },
    [router, setCookieLanguage],
  );

  /**
   * Keep internal language in sync with translations
   */
  useEffect(() => {
    if (!previousLanguage || previousLanguage === language) {
      return;
    }

    setLanguageAndCookie(language);
  }, [previousLanguage, language, setLanguageAndCookie]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguageAndCookie,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  if (context === null) {
    throw new Error(
      'Please use useLanguageContext within an LanguageContextProvider',
    );
  }

  return context;
};
