import * as Sentry from '@sentry/nextjs';
import { Action } from 'redux-actions';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';

import { FETCH, fetchSuccess } from './actions';

function* fetchChartFlow({
  payload,
}: Action<{ coinId: string; period: string }>) {
  try {
    if (!payload) {
      return;
    }

    const { coinId, period } = payload;

    const { data: response, status } = yield call(
      api.get,
      `/markets/charts/${coinId}`,
      {
        params: {
          period,
        },
      },
    );

    if (status !== 200) {
      return;
    }

    if (response.meta.success === false) {
      return;
    }

    yield put(fetchSuccess(coinId, period, response.data.graph));
  } catch (e) {
    Sentry.captureException(e);
  }
}
function* fetchChartFlowWatcher() {
  yield takeEvery(FETCH, fetchChartFlow);
}

export default [fork(fetchChartFlowWatcher)];
