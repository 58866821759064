export enum PlatformType {
  IOS = 'PlatformType.IOS',
  ANDROID = 'PlatformType.ANDROID',
  WINDOWS_PHONE = 'PlatformType.WINDOWS_PHONE',
  MACOS = 'PlatformType.MACOS',
  WINDOWS = 'PlatformType.WINDOWS',
  WINDOWS32 = 'PlatformType.WINDOWS32',
  WINDOWS64 = 'PlatformType.WINDOWS64',
  LINUX = 'PlatformType.LINUX',
  UNKNOWN = 'PlatformType.UNKNOWN',
}

class Platform {
  private _header: string;

  public constructor(header?: string) {
    this._header =
      header ||
      (typeof window !== 'undefined' &&
        window.navigator &&
        window.navigator.userAgent) ||
      '';
  }

  public setHeader(header: string) {
    this._header = header || '';
  }

  public get header(): string {
    return this._header;
  }

  public get platform(): PlatformType {
    if (/iPad|iPhone|iPod/.test(this._header)) {
      return PlatformType.IOS;
    }

    if (this._header.indexOf('Android') !== -1) {
      return PlatformType.ANDROID;
    }

    if (this._header.indexOf('Windows Phone') !== -1) {
      return PlatformType.WINDOWS_PHONE;
    }

    if (this._header.indexOf('Mac') !== -1) {
      return PlatformType.MACOS;
    }

    if (this._header.indexOf('Windows') !== -1) {
      if (this._header.indexOf('WOW64')) {
        return PlatformType.WINDOWS64;
      }

      if (this._header.indexOf('Win64')) {
        return PlatformType.WINDOWS64;
      }

      return PlatformType.WINDOWS32;
    }

    if (this._header.indexOf('Linux') !== -1) {
      return PlatformType.LINUX;
    }

    return PlatformType.UNKNOWN;
  }

  public get isIos(): boolean {
    return this.platform === PlatformType.IOS;
  }

  public get isAndroid(): boolean {
    return this.platform === PlatformType.ANDROID;
  }

  public get isWindowsPhone(): boolean {
    return this.platform === PlatformType.WINDOWS_PHONE;
  }

  public get isMobile(): boolean {
    if (this.isWindowsPhone) {
      return true;
    }

    if (this.isAndroid) {
      return true;
    }

    if (this.isIos) {
      return true;
    }

    return false;
  }

  public get isMacos(): boolean {
    return this.platform === PlatformType.MACOS;
  }

  public get isWindows(): boolean {
    if (this.platform === PlatformType.WINDOWS64) {
      return true;
    }

    if (this.platform === PlatformType.WINDOWS32) {
      return true;
    }

    if (this.platform === PlatformType.WINDOWS) {
      return true;
    }

    return false;
  }

  public get isLinux(): boolean {
    return this.platform === PlatformType.LINUX;
  }

  public get isWeb(): boolean {
    return this.isMacos || this.isWindows || this.isLinux;
  }
}

export default new Platform();
