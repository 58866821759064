import { Action, createAction } from 'redux-actions';

import { CoinInfo } from './types';

export enum ActionType {
  FETCH = 'coin.info.fetch',
  FETCH_SUCCESS = 'coin.info.fetch.success',
}

export type FetchAction = Action<{ coinId: string }>;

export const fetch: (coinId: string) => FetchAction = createAction(
  ActionType.FETCH,
  (coinId: string) => ({
    coinId,
  }),
);

export const fetchSuccess = createAction(
  ActionType.FETCH_SUCCESS,
  (coinId: string, coinInfo: CoinInfo) => ({
    coinId,
    coinInfo,
  }),
);
