import { Action, createAction } from 'redux-actions';

export enum ActionType {
  SET_COOKIES_ALLOWED = 'misc.set.cookies.allowed',
}

export type SetCookiesAllowedAction = Action<{ value: boolean }>;
export const setCookiesAllowed: (
  value: boolean,
) => SetCookiesAllowedAction = createAction(
  ActionType.SET_COOKIES_ALLOWED,
  (value: boolean) => ({
    value,
  }),
);
