import { Action, createAction } from 'redux-actions';

import { Direct } from './types';

export enum ActionType {
  FETCH_ALL = 'coin.direct.fetch.all',
  FETCH_ALL_SUCCESS = 'coin.direct.fetch.all.success',
}

export type FetchAllAction = Action<{ coinId: string }>;

export const fetchAll: (coinId: string) => FetchAllAction = createAction(
  ActionType.FETCH_ALL,
  (coinId: string) => ({
    coinId,
  }),
);
export const fetchAllDirectsSuccess = createAction(
  ActionType.FETCH_ALL_SUCCESS,
  (coinId: string, directs: Direct[]) => ({
    coinId,
    directs,
  }),
);
