import { SupportedLanguage } from '@getdelta/translations/web';
import parser from 'accept-language-parser';
import { useCallback, useState } from 'react';
export const useBrowserLanguage = () => {
  const [
    internalBrowserLanguage,
    setInternalBrowserLanguage,
  ] = useState<SupportedLanguage | null>(null);

  const setBrowserLanguage = useCallback((languageHeader: string) => {
    const browserLanguage = parser.pick(
      Object.values(SupportedLanguage),
      languageHeader,
      {
        loose: true,
      },
    );

    setInternalBrowserLanguage(browserLanguage as SupportedLanguage);
  }, []);

  return { browserLanguage: internalBrowserLanguage, setBrowserLanguage };
};
