import 'react-typist/dist/Typist.css';
import 'styles/base/globals.css';
import 'styles/base/core.css';

import * as Sentry from '@sentry/nextjs';
import { LanguageContextProvider } from 'context/Language';
import { Request as ExpressRequest } from 'express';
import { AppContext, AppProps } from 'next/app';
import Router from 'next/router';
import withReduxSaga from 'next-redux-saga';
import withRedux from 'next-redux-wrapper';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import CookieService, { Cookies } from 'services/cookie';
import PlatformService from 'services/platform';
import initStore from 'store';
import { setCookiesAllowed } from 'store/Misc/actions';
import { theme, ThemeProvider } from 'styles';

interface Props {
  store: any;
  err?: any;
}

interface InitialProps {
  languageHeaders: string;
}

const App = ({
  store,
  Component,
  pageProps,
  languageHeaders,
  err,
}: AppProps & Props & InitialProps) => {
  useEffect(() => {
    store.dispatch(
      setCookiesAllowed(
        CookieService.get(Cookies.COOKIE_CONSENT_GIVEN) === 'true',
      ),
    );

    PlatformService.setHeader(window.navigator.userAgent || '');
  }, [store]);

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LanguageContextProvider languageHeaders={languageHeaders}>
          <Component {...modifiedPageProps} />
        </LanguageContextProvider>
      </Provider>
    </ThemeProvider>
  );
};

type DeltaContextAdditions = {
  req?: ExpressRequest;
  res?: {
    locals: {
      basicRoutes: string[];
      route?: string;
    };
  };
};

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

App.getInitialProps = async ({
  Component,
  ctx,
}: AppContext & { ctx: DeltaContextAdditions }) => {
  const { req, res, query, pathname, asPath } = ctx;
  const isServer = !!req;

  Sentry.configureScope((scope) => {
    scope.setExtra('ssr', isServer);
    scope.setExtra('query', query);
    scope.setExtra('pathname', pathname);
    scope.setExtra('as-path', asPath);

    if (req) {
      scope.setExtra('url', req.url);
      scope.setExtra('method', req.method);
      scope.setExtra('headers', req.headers);
    }
  });

  if (isServer) {
    if (req && typeof req.headers.cookie === 'string') {
      CookieService.setHeader(req.headers.cookie);
    }

    if (req && typeof req.headers['user-agent'] === 'string') {
      PlatformService.setHeader(req.headers['user-agent']);
    }

    if (res) {
      // keep track of route, used for sending to Datadog APM
      if (res.locals) {
        res.locals.route = pathname;
      }
    }
  }

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const languageHeaders = ctx.req?.headers?.['accept-language'];

  return {
    pageProps,
    languageHeaders,
  };
};

export default withRedux(initStore)(withReduxSaga(App));
