import { useState } from 'react';
import CookieService, { CookieOptions, CookieValue } from 'services/cookie';

export const useCookie = (
  key: string,
  initialValue: CookieValue,
): [CookieValue, (item: CookieValue, options?: CookieOptions) => void] => {
  const [value, setInnerValue] = useState(
    CookieService.get(key) || initialValue,
  );

  const setValue = (
    value: CookieValue,
    options: CookieOptions = { path: '/' },
  ) => {
    setInnerValue(value);
    CookieService.set(key, value, options);
  };

  return [value, setValue];
};
