import produce from 'immer';
import { Action } from 'redux-actions';
import CookieService, { Cookies } from 'services/cookie';

import { ActionType } from './actions';

export interface State {
  cookiesAllowed: boolean;
}

const EMPTY_STATE: State = {
  cookiesAllowed: CookieService.get(Cookies.COOKIE_CONSENT_GIVEN) === 'true',
};

export default produce((draft: State, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionType.SET_COOKIES_ALLOWED: {
      const { value } = payload;

      draft.cookiesAllowed = value;
      return;
    }
  }
}, EMPTY_STATE);
