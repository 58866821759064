import * as Sentry from '@sentry/nextjs';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import api from 'services/api';

import { ActionType, fetchAllSuccess } from './actions';

function* fetchAllFlow() {
  try {
    const { data: response, status } = yield call(api.get, '/currencies');

    if (status !== 200) {
      return;
    }

    if (response.meta.success === false) {
      return;
    }

    yield put(fetchAllSuccess(response.data));
  } catch (e) {
    Sentry.captureException(e);
  }
}
function* fetchAllFlowWatcher() {
  yield takeEvery(ActionType.FETCH_ALL, fetchAllFlow);
}

export default [fork(fetchAllFlowWatcher)];
