import UniversalCookie, { CookieSetOptions } from 'universal-cookie';

export enum Cookies {
  COOKIE_CONSENT_GIVEN = 'cookie-consent-given',
  BETA_BANNER_HIDDEN = 'beta-banner-hidden',
  SMART_APP_BANNER_HIDDEN = 'smart-app-banner-hidden',
  LANGUAGE = 'NEXT_LOCALE',
}

export type CookieValue = string | boolean | null;
export type CookieOptions = CookieSetOptions;

class Cookie {
  private _client: UniversalCookie;

  public constructor(header?: string) {
    if (!header) {
      this._client = new UniversalCookie();
    }

    this._client = new UniversalCookie(header);
  }

  public setHeader(header: string) {
    this._client = new UniversalCookie(header);
  }

  public get = (key: string): string => {
    return this._client.get(key);
  };

  public set = (
    key: string,
    value: CookieValue,
    options?: CookieOptions,
  ): void => {
    this._client.set(key, value, options);
  };

  public remove = (key: string) => {
    this._client.remove(key);
  };

  public getClient = () => this._client;
}

export default new Cookie();
