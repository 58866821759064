import produce from 'immer';
import { Action } from 'redux-actions';

import { ActionType } from './actions';
import { Currency, USD } from './types';

export interface State {
  activeItem: Currency;
  items: Currency[];
}

const EMPTY_STATE: State = {
  activeItem: USD,
  items: [USD],
};

export default produce((draft: State, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionType.SET_ACTIVE: {
      const { item } = payload;

      draft.activeItem = item;
      return;
    }
    case ActionType.FETCH_ALL_SUCCESS: {
      const { items } = payload;

      draft.items = items;
      return;
    }
  }
}, EMPTY_STATE);
