import { all } from 'redux-saga/effects';

import CoinAnalysis from './Coin/Analysis/sagas';
import CoinDirect from './Coin/Direct/sagas';
import CoinInfo from './Coin/Info/sagas';
import Currency from './Currency/sagas';
import MarketsCharts from './Market/Chart/sagas';
import Markets from './Market/sagas';
import MarketsSummary from './Market/Summary/sagas';
import Misc from './Misc/sagas';

export default function* () {
  yield all([
    ...CoinInfo,
    ...CoinDirect,
    ...CoinAnalysis,
    ...Currency,
    ...MarketsSummary,
    ...Markets,
    ...MarketsCharts,
    ...Misc,
  ]);
}
