import translations from '@getdelta/translations/web';
import { TimePeriods } from 'components/TimePeriodPicker';
import { TFunction } from 'i18next';
import * as qs from 'qs';
import { Coin } from 'store/Coin/types';
import { Currency } from 'store/Currency/types';

import {
  formatHumanReadableNumber,
  formatMoney,
  formatPercentage,
} from './formatters/number';

export const getTranslationForCoinDescription = (
  t: TFunction,
  coin: Coin,
  currency: Currency,
): string => {
  let description = coin.priceInUSD
    ? t('page.assets.detail.meta.description.p1', {
        name: coin.name,
        code: coin.code.toUpperCase(),
        price: coin.priceInUSD && formatMoney(coin.priceInUSD, currency),
        marketCap:
          coin.marketCapInUSD &&
          formatHumanReadableNumber(coin.marketCapInUSD, currency),
        changeType:
          coin.percentChange24h && coin.percentChange24h < 0
            ? t('label.decreased')
            : t('label.increased'),
        change:
          coin.percentChange24h &&
          formatPercentage(Math.abs(coin.percentChange24h)),
      })
    : '';

  if (coin.availableSupply && coin.totalSupply) {
    if (coin.priceInUSD) {
      description += ' ';
    }

    if (coin.availableSupply === coin.totalSupply) {
      description += t('page.assets.detail.meta.description.p2-alt', {
        name: coin.name,
        code: coin.code.toUpperCase(),
        circulatingSupply: formatHumanReadableNumber(coin.availableSupply),
      });
    } else {
      description += t('page.assets.detail.meta.description.p2', {
        name: coin.name,
        code: coin.code.toUpperCase(),
        circulatingSupply: formatHumanReadableNumber(coin.availableSupply),
        totalSupply: formatHumanReadableNumber(coin.totalSupply),
      });
    }
  }

  return description;
};

export const getTranslationForSortingOption = (
  t: TFunction,
  option: string,
): string => {
  switch (option) {
    case 'ALPHABETICAL':
      return t('label.alphabetical');
    case 'HIGHEST_CAP':
      return t('label.highest-cap');
    case 'LOWEST_CAP':
      return t('label.lowest-cap');
    case 'HIGHEST_VOLUME':
      return t('label.highest-volume');
    case 'LOWEST_VOLUME':
      return t('label.lowest-volume');
    case 'HIGHEST_PRICE':
      return t('label.highest-price');
    case 'LOWEST_PRICE':
      return t('label.lowest-price');
    case 'BIGGEST_GAINERS':
      return t('label.biggest-gainers');
    case 'BIGGEST_LOSERS':
      return t('label.biggest-losers');
  }

  return '';
};

export const getTranslationForChange = (
  t: TFunction,
  period: string,
): string => {
  switch (period) {
    case TimePeriods.Hour:
      return t('label.change-1h');
    case TimePeriods.Day:
      return t('label.change-24h');
    case TimePeriods.Week:
      return t('label.change-7d');
  }

  return '';
};

/**
 * Attempt to translate the base path of the given URL according to the current locale
 */
export const getTranslatedURLBasePath = (path: string, language?: string) => {
  // Hardcode specific rewrite/redirect routes so they can be translated correctly 💩
  switch (path) {
    case '/trackers/crypto':
      path = '/crypto-tracker';
      break;
    case '/trackers/stocks':
      path = '/stocks-tracker';
      break;
    case '/trackers/nft':
      path = '/nft-tracker';
      break;
  }

  // Remove leading slash
  const strippedAs = path.replace(/^\/|\/$/g, '');
  const [basePath, ...remainingPath] = strippedAs.split('/');

  // Not a route that has translations
  if (!translations.routes().includes(basePath)) {
    return path;
  }

  // Get translated route
  const translatedBasePath = translations.t(`url.${basePath}` as any, {
    lng: language,
  });

  // No translation found, so default to base url (sanity)
  if ((translatedBasePath as any).startsWith('url.')) {
    return path;
  }

  const remainingPathString = remainingPath.length
    ? '/' + remainingPath.join('/')
    : '';

  return `/${translatedBasePath}${remainingPathString}`;
};

export const getTranslatedURL = (
  path: string,
  query: Record<string, string | string[] | undefined>,
  language?: string,
) => {
  // make sure to not mutate the original query object
  query = { ...query };

  let url = process.env.NEXT_PUBLIC_URL!;
  if (path) {
    path = getPopulatedDynamicURL(path, query, true);
    url += '/';
    if (language === 'x-default') {
      url += getTranslatedURLBasePath(path, 'en').replace(/^\/+/, '');
    } else {
      url += `${language}/`;
      url += getTranslatedURLBasePath(path, language).replace(/^\/+/, '');
    }
  }
  if (Object.keys(query).length > 0) {
    url += '?' + qs.stringify(query);
  }
  return url;
};

/**
 * Attempt to populate the dynamic URL based on the query properties
 */
export const getPopulatedDynamicURL = (
  path: string,
  query: any,
  deleteFromQuery: boolean = false,
) => {
  let populatedPath = path;
  const parts = path.match(/\[(.*?)\]/g);

  if (!parts) {
    return path;
  }

  for (const part of parts) {
    const value = part.substring(1, part.length - 1);
    populatedPath = populatedPath.replace(part, query[value]);
    if (deleteFromQuery) {
      delete query[value];
    }
  }

  return populatedPath;
};
